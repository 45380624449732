'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var dom = require('./dom');
var settings = require('./settings');

var instanceMap = {};

var create = function(formId) {

	return instanceMap[formId] = new Time(formId);

};

var get = function(formId) {

	return instanceMap[formId];

};

var Time = function(formId) {

	this.formId = formId;
	this.domInstance = dom.get(formId);
	this.settingsInstance = settings.get(formId);
	this.$timeFields = this.domInstance.form.find('.fb-time-type');

	this.domInstance.form.on('fbWidget-destroy', this.destroy.bind(this));

	// Remove unneeded elements from DOM depending on desired UI based on feature tests

	if (this.settingsInstance.useNativeTime) {

		// Get rid of the time picker

		this.$timeFields
			.find('.fb-time-picker')
				.remove();

		// Mobile safari collapses the height of inputs with a type of "time" or "date" when the input has no value. In order to get around this, we have to initially setup the type as "text", and then dynamically switch it to "time" whenever the field is focused. Just calling this.focus() works in mobile safari, but we have to also call this.click() for chrome android.
		this.$timeFields.find('.fb-native-time').attr('onfocus', 'this.type="time";this.blur();this.onfocus="";this.focus();this.click();');

	} else {

		// Block users from entering text
		this.$timeFields.find('.fb-native-time').attr('readonly', '');

		this.domInstance.form
			.on('focus', '.fb-native-time', this.onTextboxFocus.bind(this))
			.on('change', '.fb-time-picker select', this.onSelectChange.bind(this))
			.on('click', this.onFormClick.bind(this));

	}

};

Time.prototype.onTextboxFocus = function(e) {

	$(e.target).closest('.fb-time-type').find('.fb-time-picker__hour select').focus();

};

Time.prototype.onSelectChange = function(e) {

	var $target = $(e.target);
	var $selects = $target.closest('.fb-time-picker').find('select');
	var $textbox = $target.closest('.fb-time-type').find('.fb-native-time');
	var vals = [];
	var val;

	// Get the value of each select into an array
	$selects.each(function() {

		var val = $(this).val();

		if (val) {

			vals.push(val);

		}

	});

	// If all selects have values, construct a time string and update the textbox, otherwise empty the textbox
	if ($selects.length === vals.length) {

		val = '';

		vals.forEach(function(v,i) {

			val += v;

			if (i === 0) {

				val += ':';

			} else if (i === 1) {

				val += ' ';

			}

		});

		$textbox.val(val);

	} else {

		$textbox.val('');
	}

	// Trigger keyup event so validation will run
	$textbox.trigger('keyup');

};

Time.prototype.onFormClick = function(e) {

	var $target = $(e.target);
	var $time = $target.closest('.fb-time-type');
	var $timePicker;

	if ($time.length) {

		// User clicked on something wihin a time field

		// Hide any other time picker currently visible
		if (!$time.hasClass('fb-time-type--has-visible-picker')) {

			this.$timeFields.removeClass('fb-time-type--has-visible-picker');

		}

		$time.addClass('fb-time-type--has-visible-picker');

		this.domInstance.document.off('.fb-timepicker-' + this.formId);
		this.domInstance.document.on('keydown.fb-timepicker-' + this.formId, function(e) {

			if (e.keyCode === 27) {

				this.domInstance.document.off('.fb-timepicker-' + this.formId);
				this.$timeFields.removeClass('fb-time-type--has-visible-picker');

			}

		}.bind(this));

	} else {

		// User clicked on something outside a time field, hide any visible time pickers
		this.$timeFields.removeClass('fb-time-type--has-visible-picker');

		this.domInstance.document.off('.fb-timepicker-' + this.formId);

	}

};

Time.prototype.destroy = function() {

	delete instanceMap[this.formId];

};

Time.prototype.isTimeType = function($col) {

	return $col.hasClass('fb-time-type');

};

Time.prototype.getVal = function($col) {

	var $textbox = this.domInstance.getFieldInputs($col).filter('.fb-native-time');
	var name = this.domInstance.getFieldTitleText($col);

	return {
		name: name,
		value: $textbox.val()
	};

};

// Time.prototype.isCustom = function(/*$el*/) {

// 	// return !!$el.closest('.js-fb-custom-time').length;
// 	return !this.settingsInstance.useNativeTime;

// };

// Time.prototype.hasGroupValidation = function($el) {

// 	return !!$el.attr('data-parsley-timegroup');
// };

// Time.prototype.getVal = function($col) {

// 	var $els = this.domInstance.getFieldInputs($col);
// 	var name = this.domInstance.getFieldTitleText($col);
// 	var val = '';

// 	if (this.settingsInstance.useNativeTime) {

// 		// Get the value of the native time element.
// 		return {
// 			name: name,
// 			value: $els.val()
// 		};

// 	}

// 	// Construct a value based on the select boxes.
// 	$els.each(function(i) {

// 		val += $els.eq(i).val();

// 		if (i === 0) {

// 			val += ':';

// 		} else if (i === 1) {

// 			val += ' ';

// 		}

// 	});

// 	val = val === ': ' ? '' : val;

// 	return {
// 		name: name,
// 		value: val
// 	};

// };

/*Time.prototype.validateCustom = function($element) {

	var $selects = $element.closest('.fb-time-type').find('select');
	var validCnt = 0;

	$selects.each(function(i) {

		if ($selects.eq(i).val()) {

			validCnt++;

		}

	});

	return validCnt === $selects.length;

};*/

module.exports = {
	create: create,
	get: get
};
