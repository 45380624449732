'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var domCache = require('dom-cache');

var dom = require('./dom');
var settings = require('./settings');

var instanceMap = {};

var create = function(formId) {

	return instanceMap[formId] = new Datepicker(formId);

};

var get = function(formId) {

	return instanceMap[formId];

};

var Datepicker = function(formId) {

	this.formId = formId;
	this.domInstance = dom.get(formId);
	this.settingsInstance = settings.get(formId);
	this.$inputs = this.domInstance.form.find('.fb-date-type input');
	this.$datepickers = $();

	if (this.settingsInstance.useJqueryUIDatepicker) {

		this.domInstance.form.on({
			'fbWidget-layout-adjusted': this.onLayoutAdjusted.bind(this)
		});

		this.$inputs.each(function(i) {

			var $input = this.$inputs.eq(i);

			$input
				.attr({
					// Trigger validation when a date is selected from the
					// picker or manually entered.
					'data-parsley-trigger': 'change'
				})
				.datepicker({
					showOtherMonths: true,
					prevText: '<<',
					nextText: '>>',
					dateFormat: this.getJqueryUIDateFormat($input)
				});

			this.$datepickers = this.$datepickers.add($input);

		}.bind(this));

		domCache.body.find('#ui-datepicker-div').addClass('fb-datepicker');

	} else {

		// Using native mobile date picker

		this.$inputs
			// Remove unnecessary validation
			.removeAttr('data-parsley-dateformat data-parsley-validdate')
			// Mobile safari collapses the height of inputs with a type of "time" or "date" when the input has no value. In order to get around this, we have to initially setup the type as "text", and then dynamically switch it to "time" whenever the field is focused. Just calling this.focus() works in mobile safari, but we have to also call this.click() for chrome android.
			.attr('onfocus', 'this.type="date";this.blur();this.onfocus="";this.focus();this.click();');

	}

	this.domInstance.form.on({
		'fbWidget-destroy': this.destroy.bind(this)
	});

};

Datepicker.prototype.destroy = function() {

	if (this.$datepickers.length) {

		this.$datepickers.datepicker('destroy');

	}

	delete instanceMap[this.formId];

};

Datepicker.prototype.getJqueryUIDateFormat = function($el) {

	return $el.attr('data-format');

};

Datepicker.prototype.isDateType = function($col) {

	return $col.hasClass('fb-date-type');

};

Datepicker.prototype.getVal = function($col) {

	var name = this.domInstance.getFieldTitleText($col);
	var $input = this.domInstance.getFieldInputs($col);
	var val = $input.val();
	// var d;

	if (val && !this.settingsInstance.useJqueryUIDatepicker) {

		// Reformat the native mobile date to match user's setting.

		val = $.datepicker.parseDate('yy-mm-dd', val);
		val = $.datepicker.formatDate(this.getJqueryUIDateFormat($input), val);

		// d = new Date(val);

		// Adjust date for timezone.
		// d.setTime(d.getTime() + d.getTimezoneOffset()*60*1000);

		// Format the date in case value came from mobile date picker.
		// val = $.datepicker.formatDate(getJqueryUIDateFormat($input), d);

	}

	return {
		name: name,
		value: val
	};

};

Datepicker.prototype.onLayoutAdjusted = function() {

	if (this.$datepickers.length) {

		this.$datepickers.datepicker('hide');

	}

};

module.exports = {
	create: create,
	get: get
};
