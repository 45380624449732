'use strict';

var lodashDebounce = require('lodash/function/debounce');

var triggerCustomEvent = require('trigger-custom-event');
var loadImage = require('load-image');

var appDom = require('../app-dom');

var instanceMap = {};

var create = function($gallery, galleryId) {

	return instanceMap[galleryId] = new Thumbnails($gallery, galleryId);

};

var get = function(galleryId) {

	return instanceMap[galleryId];

};

var Thumbnails = function($gallery, galleryId) {

	this.$gallery = $gallery;
	this.galleryId = galleryId;
	this.$thumbViewport = this.$gallery.find('.gallery__thumb-viewport');
	this.$style = this.$thumbViewport.find('.gallery__thumb-style-element');
	this.$thumbContainer = this.$thumbViewport.find('.gallery__thumbs');
	this.$thumbs = this.$thumbContainer.find('.gallery__thumb');
	this.$activeThumb = this.$thumbs.filter('.gallery__thumb--is-active');
	this.thumbOffset = 0;
	this.$queue = [];
	this.isTransitioning = false;

	this.$gallery.on({
		'gallery_item_changed': this.onGalleryItemChanged.bind(this),
		'gallery_items_fetched': this.onGalleryItemsFetched.bind(this)
	});

	this.$thumbViewport.on('click', '.js-select-thumb', this.selectThumb.bind(this));

	this.$thumbContainer.on('transitionend', this.onSlideTransitionEnd.bind(this));

	appDom.window.on('resize.gallery-thumbnails-' + this.galleryId, lodashDebounce(this.slideThumbs.bind(this, false), 200));

};

Thumbnails.prototype.destroy = function() {

	appDom.window.off('.gallery-thumbnails-' + this.galleryId);
	instanceMap[this.galleryId] = null;

};

Thumbnails.prototype.setActiveThumb = function($thumb) {

	this.$activeThumb.removeClass('gallery__thumb--is-active');
	this.$activeThumb = $thumb.addClass('gallery__thumb--is-active');

};

Thumbnails.prototype.preloadImages = function() {

	var instance = this;
	var activeDomIndex = this.$thumbs.index(this.$activeThumb);
	var numThumbs = this.$thumbs.length;
	var css = '';

	this.lazyLoadRange.forEach(function(rangeIndex) {

		var targetIndex = activeDomIndex - rangeIndex;
		var $img;
		var imgSrc;
		var cssTargetIndex;

		if (targetIndex >= numThumbs || targetIndex < 0 - numThumbs) {

			// Make sure we're not looking too far to the right or left
			targetIndex = targetIndex % numThumbs;

		}

		cssTargetIndex = targetIndex >= 0 ? targetIndex + 1 : numThumbs + targetIndex + 1;
		css += (css ? ',' : '') + '#' + instance.galleryId + ' .gallery__thumb:nth-child(' + cssTargetIndex + ') .gallery__thumb-img';

		$img = instance.$thumbs.eq(targetIndex).find('.gallery__thumb-img');

		if (!$.data($img[0], 'isLoaded')) {

			imgSrc = $img.attr('data-gallery-img-src');

			// Make sure this thumb has an image
			if (imgSrc) {

				loadImage(imgSrc, function() {

					$img
						.css('background-image', 'url(' + imgSrc + ')')
						.removeClass('u-img-loading');

					$.data($img[0], 'isLoaded', true);

				});

			}

		}

	});

	this.$style.html('#' + this.galleryId + ' .gallery__thumb-img {display: none;} ' + css + '{display: block;}');

};


// Preload 6 to the left and right of the active item
Thumbnails.prototype.lazyLoadRange = [0, -1, -2, -3, -4, -5, -6, 1, 2, 3, 4, 5, 6];

Thumbnails.prototype.injectThumbs = function() {

	var $thumbsToInject;
	var lowSequence;
	var highSequence;
	var $sequenceNeighbors;
	var $sequenceNeighbor;
	var i;

	for (i = 0; i < 2; i++) {

		if (this.$queue.length) {

			if (i === 0) {

				// Get the first 3 from the queue
				$thumbsToInject = this.$queue.slice(0, 3);

				// Remove from the queue
				this.$queue = this.$queue.slice(3);

			} else {

				// Get the last 3 from the queue
				$thumbsToInject = this.$queue.slice(-3);

				// Remove from the queue
				this.$queue = this.$queue.slice(0, -3);

			}

			// Try to find a sequence in the DOM that is either one before the lowest sequence in our batch or one after the highest sequence in our batch. We're grouping the filter so we only have to search once.
			lowSequence = this.getThumbSequence($thumbsToInject.first());
			highSequence = this.getThumbSequence($thumbsToInject.last());
			$sequenceNeighbors = this.$thumbs.filter('[data-gallery-thumb-sequence="' + (lowSequence - 1) + '"],[data-gallery-thumb-sequence="' + (highSequence + 1) + '"]');

			if ($sequenceNeighbors.length) {

				$sequenceNeighbor = $sequenceNeighbors.first();

				if (this.getThumbSequence($sequenceNeighbor) === lowSequence - 1) {

					$sequenceNeighbor.after($thumbsToInject);

				} else {

					$sequenceNeighbor.before($thumbsToInject);

				}

			} else {

				// No target found, so just inject at the end
				this.$thumbContainer.append($thumbsToInject);

			}

			// Refresh thumbs cache
			this.$thumbs = this.$thumbContainer.find('.gallery__thumb');

			// Depending on where the thumbs were injected, the thumbs visible on screen may have been pushed to the right, so before we continue, we need to pull everything back to where it was visually before we injected the new thumbs. Note that we are passing true to disable the sliding transition, so that the user does not see any of this pushing and pulling.
			this.slideThumbs(true);

		}

	}

};

Thumbnails.prototype.onSlideTransitionEnd = function() {

	this.isTransitioning = false;

};

Thumbnails.prototype.slideThumbs = function(disableTransition) {

	var thumbViewportWidth = this.$thumbViewport.width();
	var thumbWidth = this.$activeThumb.width();
	var thumbOuterWidth = this.$activeThumb.outerWidth();
	var gutter = thumbOuterWidth - thumbWidth;
	var centeredThumbLeftPosition = (thumbViewportWidth - thumbWidth) / 2;
	var thumbLeftPosition = this.$activeThumb.position().left + gutter;
	var difference = thumbLeftPosition - centeredThumbLeftPosition;
	var numThumbs = this.$thumbs.length;
	var widthOfAllThumbs = numThumbs * thumbOuterWidth - gutter;
	var maxOffset = -(widthOfAllThumbs - thumbViewportWidth);
	var minOffset = 0;
	var oldThumbOffset = this.thumbOffset;

	if (widthOfAllThumbs <= thumbViewportWidth) {

		return;

	}

	this.isTransitioning = true;

	this.$thumbContainer.toggleClass('gallery__thumbs--has-disabled-transition', !!disableTransition);

	if (this.thumbOffset - difference < maxOffset) {

		// Never allow the thumbs to slide away from the right edge
		this.thumbOffset = maxOffset;

	} else if (this.thumbOffset - difference > minOffset) {

		// Never allow the thumbs to slide away from the left edge
		this.thumbOffset = minOffset;

	} else {

		this.thumbOffset -= difference;

	}

	this.$thumbContainer.css('transform', 'translate3d(' + this.thumbOffset + 'px,0,0)');

	// Adust old and new offset to whole numbers to fix issue when comparing decimal old with whole new, which could happen if new is set to the maxOffset
	if (disableTransition || oldThumbOffset.toFixed() === this.thumbOffset.toFixed()) {

		this.isTransitioning = false;

	}

};

Thumbnails.prototype.onGalleryItemChanged = function(e, data) {

	var $oldActiveThumb = this.$activeThumb;

	this.injectThumbs();
	// Find the thumb that matches the sequence of the new item
	this.setActiveThumb(this.$thumbs.filter('[data-gallery-thumb-sequence="' + data.activeSequence + '"]'));

	this.preloadImages();

	if (
		($oldActiveThumb.is(':first-child') && this.$activeThumb.is(':last-child')) ||
		($oldActiveThumb.is(':last-child') && this.$activeThumb.is(':first-child'))
	) {

		//  The more thumbs we have the worse the slide transition gets, so disable the transition and do a hard slide when going from the first to last or last to first
		this.slideThumbs(true);

	} else {

		this.slideThumbs();

	}

};

Thumbnails.prototype.onGalleryItemsFetched = function(e, data) {

	// Wait until current slide ends before processing and injecting to avoid visual jank from the non-transitioned slide that happens after injecting new items
	if (this.isTransitioning) {

		this.$thumbContainer.one('transitionend', this.onGalleryItemsFetched.bind(this, e, data));

	} else {

		this.$queue = data.$thumbs;
		this.injectThumbs();
		this.preloadImages();

	}

};

Thumbnails.prototype.getThumbSequence = function($thumb) {

	if ($thumb.data) {

		return $thumb.data('galleryThumbSequence');

	} else {

		// $thumb is actually a dom node
		return parseInt($thumb.getAttribute('data-gallery-thumb-sequence'), 10);

	}

};

Thumbnails.prototype.selectThumb = function(e) {

	var $thumb;

	if (this.isTransitioning) {

		return;

	}

	$thumb = $(e.target).closest('.gallery__thumb');

	if (this.$activeThumb.is($thumb)) {

		return;

	}

	this.injectThumbs();
	this.setActiveThumb($thumb);
	this.preloadImages();
	this.slideThumbs();

	triggerCustomEvent('gallery_thumb_changed', {
		target: this.$gallery,
		data: {
			$activeThumb: this.$activeItem,
			activeSequence: this.getThumbSequence(this.$activeThumb)
		}
	});

};

module.exports = {
	create: create,
	get: get
};
