'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var get = function(action, queryData) {

	var data = queryData || {};

	data.action = action;

	return '/index.cfm?' + $.param(data);

};

module.exports = {
	get: get
};
