'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var domCache = require('dom-cache');
var scrollElement = require('scroll-element');

/*

element: selector or jQuery object for element you want to scroll to - REQUIRED
container: selector or jQuery object for scrolling element that contains element - default 'html,body'
scrollTopOffset: number of pixels to offset scrollTop position, relative to element - default 0
scrollLeftOffset: number of pixels to offset scrollLeft position, relative to element - default 0
speed: number of ms for scroll animation - default 400
force: scroll container to top of element, even if it is currently in view - default false

*/

module.exports = function(opts) {

	opts = opts || {};
	opts.speed = typeof opts.speed === 'undefined' ? 400 : opts.speed;
	opts.scrollTopOffset = opts.scrollTopOffset || 0;
	opts.scrollLeftOffset = opts.scrollLeftOffset || 0;

	var $windowContainer = domCache.html.add(domCache.body);
	var $container = opts.container ? $(opts.container) : $windowContainer;
	var $el = $(opts.element);
	// Separate check in case 'html,body' is passed in as container in options.
	var isContainerWindow = $container.is($windowContainer);
	var elOffset = $el.offset();
	var top;
	var left;
	var containerOffset;

	if (isContainerWindow) {

		top = elOffset.top - opts.scrollTopOffset;
		left = elOffset.left - opts.scrollLeftOffset;

	} else {

		containerOffset = $container.offset();
		top = (elOffset.top + $container.scrollTop()) - containerOffset.top - opts.scrollTopOffset;
		left = (elOffset.left + $container.scrollLeft()) - containerOffset.left - opts.scrollLeftOffset;

	}

	// Only scroll to element if it is not in view already.
	if (
		(opts.force) ||
		(isContainerWindow && (
			top < window.pageYOffset ||
			left < window.pageXOffset
		)) ||
		(!isContainerWindow && (
			top < $container.scrollTop() ||
			left < $container.scrollLeft()
		))
	) {

		return scrollElement({
			container: $container,
			speed: opts.speed,
			scrollTop: top,
			scrollLeft: left,
			callback: opts.callback
		});

	} else {

		// TODO: Return resolved jquery deferred object.

	}

};
