'use strict';

// var remove = require('lodash/array/remove');

var $ = (typeof window !== 'undefined' ? window.jQuery : typeof global !== 'undefined' ? global.jQuery : null);
// Make a require() call for parsley to make sure it's included in the build.
// Parsley creates several global objects from one file, which is not supported
// by browserify-shim.
require('parsley');
var ParsleyValidator = (typeof window !== 'undefined' ? window.ParsleyValidator : typeof global !== 'undefined' ? global.ParsleyValidator : null);
var ParsleyUI = (typeof window !== 'undefined' ? window.ParsleyUI : typeof global !== 'undefined' ? global.ParsleyUI : null);

var domCache = require('dom-cache');
var notify = require('notify');
var pbizServer = (typeof window !== "undefined" ? window.pbizServer : typeof global !== "undefined" ? global.pbizServer : null);
var recaptcha = require('recaptcha');

var dom = require('./dom');
var fileupload = require('./fileupload');
var time = require('./time');
var option = require('./option');
var address = require('./address');
var fullname = require('./fullname');
var rating = require('./rating');
var payment = require('./payment');
var familyfriends = require('./familyfriends');
var datepicker = require('./datepicker');
var settings = require('./settings');
var outdatedMsg = require('./outdated-msg');

var instanceMap = {};

var create = function(formId) {

	return instanceMap[formId] = new Validate(formId);

};

var get = function(formId) {

	return instanceMap[formId];

};

var Validate = function(formId) {

	this.formId = formId;
	this.domInstance = dom.get(formId);
	this.fileuploadInstance = fileupload.get(formId);
	this.timeInstance = time.get(formId);
	this.optionInstance = option.get(formId);
	this.addressInstance = address.get(formId);
	this.fullnameInstance = fullname.get(formId);
	this.ratingInstance = rating.get(formId);
	this.paymentInstance = payment.get(formId);
	this.familyfriendsInstance = familyfriends.get(formId);
	this.datepickerInstance = datepicker.get(formId);
	this.settingsInstance = settings.get(formId);
	this.outdatedMsgInstance = outdatedMsg.get(formId);
	this.state = {
		parsleyFormInstance: null
	};

	this.setupRecaptcha();
	this.addCustomValidators();
	this.setupForm();
	this.addEventHandlers();

};

Validate.prototype.setupRecaptcha = function() {

	if (this.domInstance.recaptcha.length) {

		// This form should use recaptcha, so set it up

		recaptcha.init(pbizServer.languageTag)
			.then(function() {

				this.recaptchaWidgetId = window.grecaptcha.render(this.domInstance.recaptcha[0], {
					callback: function() {
						// The recaptcha challenge has been completed, submit the form
						this.onFormSubmit();
					}.bind(this)
				}, true);

			}.bind(this))
			.catch(console.warn);

	}

};

Validate.prototype.validClass = 'fb-validate-valid';
Validate.prototype.errorClass = 'fb-validate-error';

Validate.prototype.parsleyOptions = {
	errorClass: Validate.prototype.errorClass,
	successClass: Validate.prototype.validClass,
	requiredMessage: pbizServer.content['Validate.ThisFieldIsRequired'],
	// Next two props disable errors from being injected into the DOM.
	// Error messaging will be manually handled through use of elMsg.js
	errorsWrapper: '',
	errorsMessagesDisabled: true,
	priorityEnabled: false,
	excluded: 'input[type="button"], input[type="submit"], input[type="reset"], input[type="hidden"], .fb-linked-hide :hidden, .fb-rating-compact input[type="radio"], .fb-rating-expanded select, .fb-payment-shipping-address-options-same + .fb-address .fb-field-input, input[type="radio"]:not(:checked) ~ .fb-payment-merchant-form .fb-field-input, .fb-address-county:not(.fb-address-county-is-visible) .fb-field-input'
};

Validate.prototype.destroy = function() {

	notify.hide();
	delete instanceMap[this.formId];

};

Validate.prototype.addEventHandlers = function() {

	this.state.parsleyFormInstance.unsubscribe('parsley:field:error');
	this.state.parsleyFormInstance.unsubscribe('parsley:field:success');
	this.state.parsleyFormInstance.unsubscribe('parsley:form:validated');

	this.state.parsleyFormInstance.subscribe('parsley:field:error', this.onParsleyFieldError.bind(this));

	this.state.parsleyFormInstance.subscribe('parsley:field:success', this.onParsleyFieldSuccess.bind(this));

	this.state.parsleyFormInstance.subscribe('parsley:form:validated', this.onParsleyFormValidated.bind(this));

	this.domInstance.inputContainer.on('focus', '.fb-field-input', this.onInputFocus.bind(this));

	this.domInstance.form
		.on({
			'reset': this.onFormReset.bind(this),
			// 'submit': this.onFormSubmit.bind(this),
			'submit': function(e) {

				e.preventDefault();

				// If this form supports recaptcha and we don't have a token yet, execute the challenge logic
				if (typeof this.recaptchaWidgetId !== 'undefined' && !window.grecaptcha.getResponse(this.recaptchaWidgetId)) {

					// Execute the recaptcha challenge, which will either immediately pass or present the user with a challenge to complete
					window.grecaptcha.execute(this.recaptchaWidgetId)
						.catch(function() {

							// An error in here most likely means the site key is incorrect

							notify.show({
								target: this.domInstance.submitBtn,
								msg: pbizServer.content['Generic.UnexpectedError'] + ' (EC7.6)',
								container: this.settingsInstance.notifyContainer === 'target' ? this.domInstance.submitBtnContainer : this.settingsInstance.notifyContainer,
								scrollContainer: this.settingsInstance.notifyScrollContainer
							});

						}.bind(this));

				} else {

					this.onFormSubmit();

				}

			}.bind(this),
			'fbWidget-familyfriends-field-added': this.onFamilyFriendsFieldAdded.bind(this),
			'fbWidget-familyfriends-field-remove': this.onFamilyFriendsFieldRemove.bind(this),
			'fbWidget-linked-field-hidden': this.onLinkedFieldHidden.bind(this),
			'fbWidget-linked-field-shown': this.onLinkedFieldShown.bind(this),
			'fbWidget-payment-shipping-address-option-changed': this.onPaymentShippingAddressOptionChanged.bind(this),
			'fbWidget-payment-method-changed': this.onPaymentMethodChanged.bind(this),
			'fbWidget-rating-layout-adjusted': this.onRatingLayoutAdjusted.bind(this),
			'fbWidget-layout-adjusted': this.onLayoutAdjusted.bind(this),
			'fbWidget-destroy': this.destroy.bind(this)
		});

};

Validate.prototype.onParsleyFieldError = function(parsleyFieldInstance) {

	var $element = parsleyFieldInstance.$element;

	// Parsley doesn't apply the error/valid classes until after this handler
	// runs. Manually swap the classes so we can update our UI accordingly.
	this.flagAsError($element);
	this.processErrors();
	this.showErrorMsg(parsleyFieldInstance);

};

Validate.prototype.onParsleyFieldSuccess = function(parsleyFieldInstance) {

	var $element = parsleyFieldInstance.$element;

	// Parsley doesn't apply the error/valid classes until after this handler
	// runs. Manually swap the classes so we can update our UI accordingly.
	this.flagAsValid($element);
	this.processErrors();

	// Time fields that have a multiple select UI are a bit unique in
	// that the field is composed of multiple inputs, but there is only
	// one title for the field as a whole. This is in contrast with
	// similar fields like address, full name, etc. that have titles
	// for each individual input. Therefore, we only want to hide a
	// notification on time fields if all of the selects are valid,
	// not just one.

	// if (this.timeInstance.isCustom(/*$element*/) && !this.timeInstance.validateCustom($element)) {

	// 	return;

	// }

	this.hideErrorMsg($element);

};

Validate.prototype.onParsleyFormValidated = function() {

	this.processErrors();

};

Validate.prototype.onInputFocus = function(e) {

	var $input = $(e.target).closest('.fb-field-input');

	this.showErrorMsg(this.getParsleyFieldInstance($input));

};

Validate.prototype.resetRecaptcha = function() {

	if (typeof this.recaptchaWidgetId !== 'undefined') {

		window.grecaptcha.reset(this.recaptchaWidgetId);

	}

};

Validate.prototype.onFormReset = function() {

	this.resetRecaptcha();

	// Reset Parsley UI.
	this.state.parsleyFormInstance.reset();

	// Reset any custom select menus.
	this.domInstance.triggerFormEvent('fbWidget-dropdown-reset', {
		$select: this.domInstance.form.find('select')
	});

};

Validate.prototype.onFormSubmit = function() {

	// Create FormData object so we can support uploading files.
	var formData = new FormData();
	var jsonData = {
		fields: []
	};
	var $filteredCols = this.domInstance.cols.not('.fb-linked-hide,.fb-policy-setting');
	var antiSpamFields = {};
	var selectedOptions = [];
	var $selectedOptions = $();
	var fieldIdx = 0;
	var uploadedFiles = [];
	var fileUploadIdx = 0;
	// Store indexes into jsonData.fields of address fields.
	// We'll need these to change the display data.
	var addressIndexes = [];
	var paymentIndex;

	if (typeof this.recaptchaWidgetId !== 'undefined') {

		formData.append('recaptchaToken', window.grecaptcha.getResponse(this.recaptchaWidgetId));

	}

	formData.append('version', 5);
	formData.append('timeStamp', this.settingsInstance.formTimeStamp);
	formData.append('dataVersionKey', this.settingsInstance.formDataVersionKey);
	formData.append('formData', this.settingsInstance.formData);

	// Don't add hidden conditional fields or policy settings to submission data
	$filteredCols.each(function(i) {

		var $col = $filteredCols.eq(i);
		var $input = this.domInstance.getFieldInputs($col);
		var fieldObj;
		var instanceId;

		if (!$input.length) {

			// This col has no user inputs, skip.
			return;

		}

		if (this.timeInstance.isTimeType($col)) {

			fieldObj = this.timeInstance.getVal($col);

		} else if (this.datepickerInstance.isDateType($col)) {

			fieldObj = this.datepickerInstance.getVal($col);

		} else if (this.optionInstance.isOptionType($col)) {

			fieldObj = this.optionInstance.getVal($col);

			if (fieldObj.value) {

				$selectedOptions = $selectedOptions.add(this.optionInstance.getSelectedOption($col));

			}

		} else if (this.addressInstance.isAddressType($col)) {

			fieldObj = this.addressInstance.getVal($col);
			addressIndexes.push(fieldIdx);

		} else if (this.fullnameInstance.isFullnameType($col)) {

			fieldObj = this.fullnameInstance.getVal($col);

		} else if (this.ratingInstance.isRatingType($col)) {

			fieldObj = this.ratingInstance.getVal($col);

		} else if (this.familyfriendsInstance.isFamilyfriendsType($col)) {

			fieldObj = this.familyfriendsInstance.getVal($col);

		} else if (this.fileuploadInstance.isFileuploadType($col)) {

			fieldObj = this.fileuploadInstance.getVal($col, fileUploadIdx);

			// Increment index if this field had a file and save the file.
			if (fieldObj.value) {

				fileUploadIdx++;
				uploadedFiles.push(this.fileuploadInstance.getFile($col));

			}

		} else if (this.paymentInstance.isPaymentType($col)) {

			paymentIndex = fieldIdx;
			fieldObj = this.paymentInstance.getVal($col);

		} else {

			fieldObj = {
				name: this.domInstance.getFieldTitleText($col),
				value: $input.val()
			};

		}

		// if ($.isArray(fieldObj)) {

		// 	Array.prototype.push.apply(jsonData.fields, fieldObj);

		// } else {

		fieldObj.id = this.domInstance.getFieldId($col);
		instanceId = this.domInstance.getInstanceId($col);

		if (instanceId) {

			fieldObj.instanceId = instanceId;

		}

		jsonData.fields.push(fieldObj);

		// }

		fieldIdx++;

	}.bind(this));

	// Parse the selected options so server can verify they are still
	// available.

	// console.log( 'options', $selectedOptions );

	$selectedOptions.each(function(i) {

		var $option = $selectedOptions.eq(i);
		var optionCoords = this.optionInstance.getOptionCoords($option);

		selectedOptions.push(optionCoords);

	}.bind(this));

	formData.append('selectedOptions', JSON.stringify(selectedOptions));

	// Add anti-spam fields to data.
	this.domInstance.getAntiSpamFields().each(function(i, el) {

		var $field = $(el);

		antiSpamFields[$field.attr('name')] = $field.val();

	}.bind(this));

	console.log( JSON.stringify(jsonData, null, '\t') );

	this.domInstance.disableSubmit();

	// Append anti-spam fields.
	Object.keys(antiSpamFields).forEach(function(el) {

		formData.append(el, antiSpamFields[el]);

	});

	// Append policy settings, if they exist in this form
	this.domInstance.cols.filter('.fb-policy-setting').each(function(i, el) {

		var $input = this.domInstance.getFieldInputs($(el));

		formData.append($input.attr('name'), $input.prop('checked') ? 1 : 0);

	}.bind(this));

	// Append the file uploads.
	uploadedFiles.forEach(function(file, idx) {

		formData.append('uploadedFiles_' + idx, file);

	});

	this.paymentInstance.processCredit(jsonData.fields[paymentIndex]).done(function() {

		// Add all the data to the FormData object.
		formData.append('data', JSON.stringify(jsonData));

		this.domInstance.triggerFormEvent('fbWidget-form-submit', {
			jsonData: jsonData
		});

		$.ajax({
			type: 'post',
			cache: false,
			// Turn off processData and contentType so file uploads will work.
			processData: false,
			contentType: false,
			url: this.settingsInstance.submitUrl,
			data: formData,
			context: this,
			xhr: function() {

				var xhr = new XMLHttpRequest();

				xhr.upload.addEventListener('progress', function(evt) {

					var status = pbizServer.content['Generic.Sending'] + '...';

					if (evt.lengthComputable) {

						status += (Math.round((evt.loaded * 100) / evt.total)) + '%';

					}

					this.domInstance.submitBtn.val(status);

				}.bind(this), false);

				return xhr;

			}.bind(this)
			// data: $.extend({}, {data: JSON.stringify(jsonData)}, antiSpamFields)
		}).done(function(data/*, textStatus, jqXHR*/) {

			var $target;
			var $notifyContainer;

			console.log( 'submission success', data );

			data = JSON.parse(data);

			if (data.status) {

				// The form was successfully submitted.

				// Inject conversion script.
				this.domInstance.form.append(data.conversionScript);

				if (data.paypal) {

					// Post to paypal, which will take user to paypal to
					// process the transaction
					this.paymentInstance.processPaypal(data.paypal);

				} else {

					if (data.redirect) {

						// The customer wants to redirect to a specific url upon
						// successful submission.
						window.location.href = data.redirect;

					} else {

						this.domInstance.availableSubmissions.text(data.availableSubmissions);
						this.domInstance.resetSubmitBtnText();

						this.domInstance.triggerFormEvent('fbWidget-form-submit-success', {
							timestamp: data.timestamp,
							documentViewUrl: data.documentViewUrl,
							paymentReceiptUrl: data.paymentReceiptUrl
						});

					}

				}

			} else {

				// An error occured.

				if (data.statusDetail === 'FORM_EXPIRED') {

					this.domInstance.form.addClass('fb-form--closed');

					this.domInstance.triggerFormEvent('fbWidget-form-expired', {
						markup: data.content
					});

				} else if (data.statusDetail === 'FORM_AT_SUBMISSION_LIMIT') {

					this.domInstance.form.addClass('fb-form--closed');

					this.domInstance.triggerFormEvent('fbWidget-form-at-submission-limit', {
						markup: data.content
					});

				} else {

					$target = this.domInstance.submitBtn;
					$notifyContainer = this.settingsInstance.notifyContainer === 'target' ? this.domInstance.submitBtnContainer : this.settingsInstance.notifyContainer;

					// A selected option is no longer available.
					if (data.statusDetail === 'UNAVAILABLE_OPTION') {

						$target = $(data.target);
						this.optionInstance.disable($target);
						$target = this.getMsgTarget($target);
						$notifyContainer = this.settingsInstance.notifyContainer === 'target' ? $target : this.settingsInstance.notifyContainer;

					} else if (data.statusDetail === 'FORM_OUTDATED') {

						this.outdatedMsgInstance.show(data.msg);
						return;

					} else if (data.statusDetail === 'RECAPTCHA_VERIFICATION_ERROR') {

						this.resetRecaptcha();

					}

					// Reset here so error message is properly centered
					this.domInstance.resetSubmitBtnText();
					this.domInstance.enableSubmit();

					notify.show({
						target: $target,
						msg: data.msg,
						container: $notifyContainer,
						scrollContainer: this.settingsInstance.notifyScrollContainer
					});

					domCache.document.one('click', notify.hide.bind(notify));

				}

			}

		}).fail(function(/*jqXHR, textStatus, errorThrown*/) {

			// console.log( 'submission error', arguments );

			// Reset here so error message is properly centered
			this.domInstance.resetSubmitBtnText();

			notify.show({
				target: this.domInstance.submitBtn,
				msg: pbizServer.content['Generic.FormCannotBeSubmitted'],
				container: this.settingsInstance.notifyContainer === 'target' ? this.domInstance.submitBtnContainer : this.settingsInstance.notifyContainer,
				scrollContainer: this.settingsInstance.notifyScrollContainer
			});

			this.domInstance.enableSubmit();

			domCache.document.one('click', notify.hide);

		});

	}.bind(this));

};

Validate.prototype.onFamilyFriendsFieldAdded = function(e, data) {

	this.domInstance.getFieldInputs(data.$row).each(function(i, el) {

		var $input = $(el);

		// Make clones optional and set them up with the correct options.
		$input
			// .removeAttr('data-parsley-required')
			.parsley(this.parsleyOptions);

		this.clearFlags($input);

	}.bind(this));

	this.processErrors();

};

Validate.prototype.onFamilyFriendsFieldRemove = function(e, data) {

	this.domInstance.getFieldInputs(data.$row).each(function(i, el) {

		// Hide notification if on an input that is about to be removed.

		this.hideErrorMsg($(el));

	}.bind(this));

};

Validate.prototype.onLinkedFieldHidden = function(e, data) {

	// Hide error notification for a linked field if it is hidden.

	this.hideErrorMsg(data.$target.find('.fb-field-container'));

	notify.position();

};

Validate.prototype.onLinkedFieldShown = function() {

	// Position error message in case the newly visible field caused
	// it to move out of place.

	notify.position();

};

Validate.prototype.onPaymentShippingAddressOptionChanged = function() {

	// Position error message in case it was moved out of place.

	notify.position();

};

Validate.prototype.onPaymentMethodChanged = function() {

	// Hide any error messages.

	notify.hide();

};

Validate.prototype.onRatingLayoutAdjusted = function() {

	if (notify.isNotificationActive() && this.ratingInstance.isElementRatingField(notify.target)) {

		// A notification is currently displayed for a rating dropdown or
		// radio.

		notify.hide();

	}

	this.state.parsleyFormInstance.validate('rating');

};

Validate.prototype.onLayoutAdjusted = function() {

	// Update the position of any notification while layout is adjusting.

	notify.position();

};

Validate.prototype.getParsleyFieldInstance = function($element) {

	return $element.parsley();

};

Validate.prototype.getErrorMsgs = function(parsleyFieldInstance) {

	return ParsleyUI.getErrorsMessages(parsleyFieldInstance);

};

Validate.prototype.getMsgTarget = function($element) {

	var $nestedFieldContainer = this.domInstance.getNestedFieldContainer($element);
	var isNested = !!$nestedFieldContainer.length;

	// Handle inside and with-icon label styles
	if ($element.attr('placeholder') || (!this.settingsInstance.areLabelsOutside && $element.is('select'))) {

		return $element.parent();

	} else {

		if (isNested) {

			// if inside, use $element
			return $nestedFieldContainer.find('.fb-field-title label');

		} else if (this.ratingInstance.isElementRatingRadio($element)) {

			return $element.closest('tr').find(':first');

		} else if ($element.closest('.fb-policy-setting').length) {

			// This special system field does not have a title, put the error message on the checkbox label
			return this.domInstance.getFieldContainer($element).find('label');

		} else {

			return this.domInstance.getFieldContainer($element).find('.fb-field-title label');

		}

	}

};

Validate.prototype.processErrors = function() {

	// Do a fresh search each time since fields can by added to the form
	// dynamically (familyfriends).
	var $fieldContainers = this.domInstance.form.find('.fb-field-container');

	$fieldContainers.each(function(i) {

		var $container = $fieldContainers.eq(i);

		if ($container.find('.' + this.errorClass).length) {

			$container.addClass(this.errorClass);

		} else {

			$container.removeClass(this.errorClass);

		}

	}.bind(this));

};

Validate.prototype.flagAsError = function($element) {

	$element
		.removeClass(this.validClass)
		.addClass(this.errorClass);

};

Validate.prototype.flagAsValid = function($element) {

	$element
		.removeClass(this.errorClass)
		.addClass(this.validClass);

};

Validate.prototype.clearFlags = function($element) {

	$element.removeClass(this.errorClass + ' ' + this.validClass);

};

Validate.prototype.showErrorMsg = function(parsleyFieldInstance) {

	var $element = parsleyFieldInstance.$element;
	var msg;

	if (!$element.hasClass(this.errorClass)) {

		return;

	}

	$element = this.getMsgTarget($element);
	msg = this.getErrorMsgs(parsleyFieldInstance)[0];

	// Only show message if its not already displayed.
	if ((!notify.isNotificationTarget($element) || notify.msg !== msg)) {

		notify.show({
			target: $element,
			msg: msg,
			container: this.settingsInstance.notifyContainer === 'target' ? $element : this.settingsInstance.notifyContainer,
			scrollContainer: this.settingsInstance.notifyScrollContainer
		});

	} else {

		notify.scrollIntoView();

	}

};

Validate.prototype.hideErrorMsg = function($element) {

	if (notify.isNotificationTarget(this.getMsgTarget($element))) {

		// An error message is currently displayed for this field.

		notify.hide();

	}

};

Validate.prototype.addCustomValidators = function() {

	var formId = this.formId.split('_').join('').toLowerCase();

	ParsleyValidator
		.addValidator('fileuploadselection' + formId, function (value, requirement) {

			// console.log( 'upload validation test', this, arguments );

			return this.fileuploadInstance.validateFileSelection(this.domInstance.form.find(requirement));

		}.bind(this), 3)
		.addMessage('en', 'fileuploadselection' + formId, pbizServer.content['Validate.ThisFieldIsRequired']);

	ParsleyValidator
		.addValidator('fileuploadtype' + formId, function (value, requirement) {

			// console.log( 'upload validation test', this );

			return this.fileuploadInstance.validateFileType(this.domInstance.form.find(requirement));

		}.bind(this), 2);

	ParsleyValidator
		.addValidator('fileuploadsize' + formId, function (value, requirement) {

			// console.log( 'upload size test', value, requirement );

			return this.fileuploadInstance.validateFileSize(this.domInstance.form.find(requirement));

		}.bind(this), 1);

	ParsleyValidator
		.addValidator('dateformat', function (value) {

			// var exp = {
			// 	'mm/dd/yyyy': /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
			// 	'dd/mm/yyyy': /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/
			// };

			return (/^\d{2}[\/]\d{2}[\/]\d{4}$/).test(value);

		}, 1)
		.addMessage('en', 'dateformat', pbizServer.content['Generic.PleaseUseFollowingFormat'] + ': %s');

	ParsleyValidator
		.addValidator('validdate', function (value, requirement) {

			var isValidDate;

			try {

				isValidDate = !!$.datepicker.parseDate(requirement, value);

			} catch(e) {

				isValidDate = false;

			}

			return isValidDate;

		}, 2)
		.addMessage('en', 'validdate', pbizServer.content['Generic.PleaseEnterValidDate']);

	/*ParsleyValidator
		.addValidator('timegroup', function (value, requirement) {

			var $field = this.domInstance.form.find(requirement);
			var $fields = this.domInstance.getFieldInputs(this.domInstance.getFieldContainer($field));
			var valCnt = 0;
			var numFields = $fields.length;
			var noneSelected;
			var someSelected;
			var allSelected;

			$fields.each(function(i) {

				if ($fields.eq(i).val()) {

					valCnt++;

				}

			});

			noneSelected = valCnt === 0;
			someSelected = valCnt > 0 && valCnt < numFields;
			allSelected = valCnt === numFields;

			if (noneSelected || allSelected) {

				return true;

			} else if (someSelected) {

				// If this component has a value, mark as valid.
				return !!$field.val();

			}

		}.bind(this), 1)
		.addMessage('en', 'timegroup', 'Please enter a complete time.');*/

};

Validate.prototype.setupForm = function() {

	this.state.parsleyFormInstance = this.domInstance.form.parsley(this.parsleyOptions);

};

module.exports = {
	create: create,
	get: get
};
