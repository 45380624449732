'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var lodashThrottle = require('lodash/function/throttle');

var notify = require('notify');
var triggerCustomEvent = require('trigger-custom-event');
var pbizServer = (typeof window !== "undefined" ? window.pbizServer : typeof global !== "undefined" ? global.pbizServer : null);

var appDom = require('./app-dom');
var utils = require('./utils/main');

var dom;

var setupDom = function() {

	dom = {};
	dom.cart = $('.cart');
	dom.cartMainContent = dom.cart.find('.cart__main-content');
	dom.cartMsg = dom.cart.find('.cart__msg');

};

var addEventHandlers = function() {

	appDom.document
		.on('click', '.cart__close-btn', close)
		.on('change', '.cart__qty', onItemQuantityChanged)
		.on('click', '.cart', onCartClicked)
		.on('click', '.cart__overlay', close)
		.on('click', '.js-remove-from-cart', onRemoveFromCartClicked)
		.on('view_cart_link_clicked', onViewCartLinkClicked)
		.on('item_added_to_cart', onItemAddedToCart)
		.on('coupon_applied', onCouponApplied)
		.on('coupon_removed', onCouponRemoved)
		.on('keypress', '.cart__coupon-input', onCouponKeypress)
		.on('click', '.cart__checkout-btn', onCheckoutBtnClicked);

};

var onCouponKeypress = function(e) {

	if (e.which === 13) {

		dom.cart.find('.cart__apply-coupon-btn').trigger('click');

	}

};

var onCheckoutBtnClicked = function(e) {

	var $link = $(this);

	e.preventDefault();

	$link.text(pbizServer.content['Generic.Loading'].toLowerCase());

	$.ajax({
		url: $link.attr('href'),
		method: 'POST'
	}).done(onCheckoutBtnClickedDone);

};

var onRemoveFromCartClicked = function(e) {

	var $link = $(this);

	e.preventDefault();

	$link.text(pbizServer.content['Generic.Loading'].toLowerCase());

	$.ajax({
		type: 'POST',
		url: utils.getUrl('cart.update'),
		data: {
			quantity: 0,
			cartPosition: $link.parents('.cart__item').attr('data-cart-position'),
			couponCode: dom.cart.find('.cart__coupon-input').val()
		}
	}).done(onItemQuantityChangedDone);

};

var onCheckoutBtnClickedDone = function(response) {

	dom.checkoutBtn = dom.cart.find('.cart__checkout-btn');

	if (response.status.code) {

		window.location = response.destination;

	} else {

		notify.show({
			msg: response.status.msg,
			target: dom.checkoutBtn,
			closeOnClick: true,
			customClasses: 'cart__error-msg'
		});

		dom.checkoutBtn.text(pbizServer.content['Miscellaneous.SecureCheckout']);

	}

};

var setupScrollHandler = function() {

	dom.cartMainContent.scroll(lodashThrottle(onCartScrolled, 250));

};

var onCartScrolled = function() {

	notify.hide();

};

var onItemAddedToCart = function() {

	processCartAdd();

};

var onCouponApplied = function() {

	retrieveCartData();

};

var onCouponRemoved = function() {

	retrieveCartData();

};

var onItemQuantityChanged = function() {

	var $selectBox = $(this);
	var $item = $selectBox.parents('.cart__item');
	var $couponInput = dom.cart.find('.cart__coupon-input');

	$.ajax({
		type: 'POST',
		url: utils.getUrl('cart.update'),
		data: {
			quantity: $selectBox.val(),
			cartPosition: $item.attr('data-cart-position'),
			couponCode: $couponInput.val()
		}
	}).done(onItemQuantityChangedDone);

};

var onItemQuantityChangedDone = function(/*response*/) {

	triggerCustomEvent('cart_item_qty_changed');

	retrieveCartData();

};

var onCartClicked = function(e) {

	notify.hide();

	e.stopPropagation();

};

var close = function() {

	dom.cart.removeClass('cart--is-open');

	notify.hide();

	triggerCustomEvent('cart_closed');

};

var isCartOpen = function() {

	return false;

};

var onViewCartLinkClicked = function() {

	if (!isCartOpen()) {

		retrieveCartData();

	}

};

var processCartAdd = function() {

	retrieveCartData(true);

};

var retrieveCartData = function(isAdd) {

	var callback;

	isAdd = typeof isAdd !== 'undefined' ? isAdd : false;
	callback = isAdd ? onAddDone : onRetrieveCartDataDone;

	$.ajax({
		type: 'POST',
		url: utils.getUrl('cart.view'),
		data: {
			isAdd: isAdd
		}
	}).done(callback);

};

var onRetrieveCartDataDone = function(response) {

	openCart(response.markup);

};

var onAddDone = function(response) {

	openCart(response.markup);

	window.setTimeout(function() {
		dom.cartMsg.slideUp(200);
	}, 2500);

};

var openCart = function(response) {

	dom.cart.addClass('cart--is-open');

	dom.cart.find('.cart__content').empty().append(response);

	setupDom();

	setupScrollHandler();

	triggerCustomEvent('cart_opened');

};

var init = function() {

	setupDom();

	addEventHandlers();

};

module.exports = {
	init: init,
	open: open
};
