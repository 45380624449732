'use strict';

var objFit = require('obj-fit');

var appDom = require('../../app-dom');

var init = function() {

	if (appDom.content.find('[data-video-block-obj-fit]').length) {

		objFit.create({
			dataAttributeName: 'video-block-obj-fit',
			target: '[data-video-block-obj-fit]'
		});

	}

};

module.exports = {
	init: init
};
